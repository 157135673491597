
import twitter from '../assets/twitter-dark.png';
import gitlab from '../assets/gitlab.jpg';
import merge from '../assets/merge.png';
import cubes from '../assets/cubes.png';
import burnextchrome from '../assets/burnextchrome.png';
import burnextff from '../assets/burnextff.png';
import ashbot from '../assets/discord_ashbot.png';
import ashburnbot from '../assets/ashburnbot.png';
import noise from '../assets/noise.png';
import burnwidget from '../assets/burn-widget.png';
import wcb from '../assets/wcb.png';





const items = [
  {
    title: 'WatchCubesBurn - beta',
    subtitle: '$ASH Ecosystem Statistics and more.',
    image: wcb,
    custom: true,
    link: 'https://beta.watchcubesburn.art',
  },
  {
    title: 'WatchCubesBurn',
    subtitle: '$ASH Ecosystem Statistics',
    image: cubes,
    link: 'https://watchcubesburn.art',
  },
  {
    title: `WatchMassMerge - offline`,
    subtitle: `Merge. by Pak, collection Statistics`,
    notice: `NOTICE: Link goes to Tina's Website`,
    image: merge,
    link: 'https://merge.tina.cafe/?ref=secondstate.xyz',
  },
  {
    title: 'ASH Price Discord Bot',
    subtitle: 'Discord bot to display the price of $ASH',
    image: ashbot,
    link: 'https://discord.com/oauth2/authorize?client_id=926943946914005003&permissions=0&scope=bot',
  },
  {
    title: 'Burn Extension - Firefox',
    subtitle: 'Browser Extension for $ASH',
    image: burnextff,
    link: 'https://addons.mozilla.org/en-CA/firefox/addon/burn-extension/',
  },
  {
    title: 'Burn Extension - Chromium',
    subtitle: 'Browser Extension for $ASH',
    image: burnextchrome,
    link: 'https://chrome.google.com/webstore/detail/burn-extension/gngcjimdeadmimigfkjhfjdmbfpipepk',
  },
  {
    title: 'Burn Widget - iOS',
    subtitle: 'Burn Widget (ASH/ETH price) for iOS via Scriptable.app',
    image: burnwidget,
    link: 'https://gitlab.com/secondstatebuilds/burn-widget',
  },
  {
    title: 'ASH Burn Bot',
    subtitle: '@ashburnbot | Burned NFT notification bot.',
    image: ashburnbot,
    link: 'https://twitter.com/ashburnbot',
  },
  {
    title: 'N O I S E',
    subtitle: 'An audiovisual NFT experience by secondstate.',
    image: noise,
    link: 'https://opensea.io/collection/noise-secondstate',
  },
  {
    title: 'Twitter',
    subtitle: '@sec0ndstate',
    image: twitter,
    link: 'https://twitter.com/sec0ndstate',
  },
  {
    title: 'GitLab',
    subtitle: 'OpenSourced Repos',
    image: gitlab,
    link: 'https://gitlab.com/secondstatebuilds',
  }
];

export default items;
